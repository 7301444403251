.landing-Page {
  margin-top: 13%;
}
.Footer-Fields {
  width: 33%;
}
.Email-input::placeholder {
  color: white;
  opacity: 70%;
}
.contact-Input::placeholder {
  color: white;
  opacity: 70%;
}
.name-Input::placeholder {
  color: white;
  opacity: 70%;
}

@media only screen and (min-width: 2100px) {
  .Footer-Fields {
    width: 24% !important;
  }
}
.Landing-Page {
  background-position: bottom;
}
.Build {
  background-position: center;
}
.social-pages {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}
.disc-br {
  display: block;
}

@media only screen and (max-width: 1280px) {
  .Footer-Fields {
    width: 35%;
  }
  .Button {
    font-size: 15px !important;
  }
  .Footer-Button {
    font-size: 15px !important;
  }
  .slider {
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  .disc-br {
    display: none;
  }
  .Email-input {
    height: 90px !important;
  }

  .linked {
    margin-top: 10px !important;
  }
  .image {
    width: 90%;
  }
  .no-br {
    display: none;
  }
  .Button {
    font-size: 12px !important;
    width: 250px !important;
  }
  .Footer-Button {
    font-size: 12px !important;
    width: 250px !important;
  }
  .logo {
    margin-top: 1%;
  }
  .Name-Field {
    flex-direction: column;
    margin: 0 !important;
  }
  .Footer-Fields {
    width: 25% !important;
  }
  .input-Contact {
    margin-top: 5% !important;
  }
  .social-container {
    margin-top: 10% !important;
    flex-direction: column;
  }
  .Footer-Fields {
    width: 30%;
  }
  .name-Input {
    width: 100% !important;
  }
  .social-pages {
    margin-top: 3%;
    width: 100%;
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .Header-Items {
    display: none !important;
  }
  .text {
    font-size: 32px !important;
  }
  .message_section {
    display: flex;
    flex-direction: column;
  }
  .New {
    text-align: center;
    font-size: 40px !important;
  }
  .Email-input {
    width: 80% !important;
  }
  .br-HomeTitle {
    display: none !important;
  }
  .Footer-components {
    flex-direction: column;
  }
  .br-no {
    display: none !important;
  }
  .Footer-Fields {
    width: 100%;
  }
  .name-Input {
    width: 40% !important;
  }
  .contact-Input {
    width: 60% !important;
  }
  .social-container {
    margin-bottom: 50px;
    width: 40% !important;
    align-items: start !important;
  }
  .Image-Div {
    display: none !important;
  }
  .Contect-Number {
    margin-top: 10%;
    width: 30%;
  }
  .Footer-Button {
    width: 200px !important;
  }
  .social-pages {
    justify-content: space-between;
    margin-top: 10%;
  }
  .Button {
    width: 345px !important;
  }
  .Text-Goal {
    font-size: 16px !important;
  }
  .Quality-Insurance {
    font-size: 16px !important;
  }
  .Texxt {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 500px) {
  .main-Heading {
    font-size: 38px !important;
  }
  .eastman-br {
    display: none;
  }
  .br-no {
    display: none !important;
  }
  .New {
    display: flex !important;
    justify-content: center !important;
  }
  .Button {
    width: 236px !important;
  }
  .Build {
    height: 730px !important;
  }
  .features {
    width: 100% !important;
  }
  .Contect-Number {
    width: 60%;
  }
  .social-container {
    width: 52% !important;
  }
  .heights-Text {
    flex-direction: column !important;
    font-size: 19px !important;
    text-align: center;
  }
  .landing-Page {
    margin-top: 150px !important;
  }
}
@media only screen and (max-width: 375px) {
  .main-Heading {
    font-size: 34px !important;
  }
  .br-no {
    display: none;
  }

  .text {
    font-size: 29px !important;
  }
  .Texxt {
    font-size: 18px !important;
  }
  .Experties {
    font-size: 18px !important;
  }
  .Features-Type-Text {
    font-size: 14px !important;
  }
  .Build {
    height: 590px !important;
  }
  .social-container {
    width: 63% !important;
  }
  .Footer-Fields {
    margin-top: 10%;
  }
  .Quality-Insurance {
    margin-top: 10%;
    font-size: 18px !important;
  }

  .Button {
    width: 250px !important;
  }
}
@media only screen and (max-width: 320px) {
  .main-Heading {
    font-size: 29px !important;
  }
  .Experties {
    font-size: 17px !important;
  }
  .Features-Type-Text {
    font-size: 12px !important;
  }
  .Quality-Insurance {
    font-size: 17px;
  }
  .Button {
    width: 200px !important;
  }
  .Contect-Number {
    width: 72%;
  }
}
@media only screen and (max-width: 280px) {
  .Contect-Number {
    width: 83% !important;
  }
}
@media only screen and (max-width: 280px) {
  .Email-font {
    font-size: 13px !important;
  }
  .logo {
    margin-top: 17%;
  }
}
