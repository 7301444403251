@media only screen and (min-width: 2100px) {
  .Footer-Fields {
    width: 22% !important;
  }
  .Footer-Button {
    width: 205px !important;
  }
}
@media only screen and (max-width: 1700px) {
  .Footer-Fields {
    width: 33% !important;
  }
}
.social-pages {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
/* Portfollio.css */
#root > div > div:nth-child(2) > .form-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.Dropdown-new {
  display: none !important;
}
#root > div > div:nth-child(2) > .form-select {
  background-color: #01eff7;
}

.arrow-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* Adjust color as needed */
  margin-left: 5px; /* Adjust spacing as needed */
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
}
.link {
  overflow-y: hidden !important;
}

.open .arrow-icon {
  transform: rotate(180deg);
}

.slider-2 {
  display: none !important;
}

/* .Footer-Fields{
    width: 33% !important;
} */
.dropdown-section {
  display: none !important;
}
/* Style the dropdown button */
.dropdown-btn {
  background-color: #01eff7;
  color: black;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.ArabicGuideLogo {
  margin-bottom: 20px !important;
}
/* Style the dropdown content */
.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style the dropdown links */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 1280px) {
  .Footer-Button {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .Name-Field {
    flex-direction: column;
    margin: 0 !important;
  }
  .input-Contact {
    margin-top: 5% !important;
  }
  .social-container {
    margin-top: 10% !important;
    flex-direction: column;
  }

  .name-Input {
    width: 100% !important;
  }
  .social-pages {
    margin-top: 3%;
    width: 100%;
    display: flex;
  }
  .Footer-Fields {
    width: 25% !important;
  }
  .Footer-Button {
    font-size: 12px !important;
    width: 195px !important;
  }
}
@media only screen and (max-width: 880px) {
  .Mian-Detail {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .All-Apps2 {
    height: 470px !important;
  }
  .All-Apps1 {
    height: 455px !important;
  }
  .All-Apps {
    height: 330px !important;
  }
  .projects {
    font-size: 18px !important;
    margin-top: 10px !important;
  }
  .App-dev {
    height: 480px !important;
  }
  .App-dev1 {
    height: 422px !important;
  }
  .App-dev2 {
    height: 571px !important;
  }
  .web-dev {
    height: 520px !important;
  }
  .web-dev-1 {
    height: 350px !important;
  }
  .web-dev-2 {
    height: 500px !important;
  }
  .Dropdown-new {
    display: block !important;
    margin-top: 1px;
  }
  .dropdown-section {
    display: block !important;
  }
  .portfolio-Type {
    height: 60px !important;
  }
  .Email-input {
    width: 60% !important;
  }
  .img-div {
    display: none !important;
  }
  .no-br {
    display: none !important;
  }
  .Projects-Details {
    margin: 0 !important;
  }
  .Footer-components {
    flex-direction: column;
  }
  .Footer-Fields {
    width: 100% !important;
  }
  .name-Input {
    width: 60% !important;
  }
  .contact-Input {
    width: 60% !important;
  }
  .social-container {
    margin-bottom: 50px;
    width: 40% !important;
    align-items: start !important;
  }
  .Contect-Number {
    margin-top: 10%;
    width: 60%;
  }
  .Footer-Button {
    width: 200px !important;
    margin-top: 15px !important;
  }
  .social-pages {
    justify-content: space-between;
    margin-top: 10%;
  }
  .Mian-Detail {
    font-size: 16px !important;
  }
  .main-Heading {
    font-size: 32px !important;
  }
  .goal_div {
    text-align: center;
    flex-direction: column !important;
    align-items: center;
  }
  .Our_Goal_brief {
    text-align: center;
  }
  .bittion {
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .Contect-Number {
    width: 60%;
  }
  .Our_Goal_brief {
    font-size: 16px !important;
  }
  .All-Apps1 {
    height: 508px !important;
  }
  .All-Apps {
    height: 400px !important;

    .projects {
      font-size: 11px !important;
    }
    margin-top: 10px !important;
    font-weight: 500;
  }
  .App-dev1 {
    height: 508px !important;
  }
  .web-dev {
    height: 671px !important;
  }
  .web-dev-1 {
    margin-top: -200px;
    height: 671px !important;
  }
  .web-dev-2 {
    margin-top: -110px;
  }
  .mobileicon1 {
    margin-top: -45px !important;
  }
  .mobileicon {
    margin-top: 0 !important;
  }
  .projects {
    font-size: 11px !important;
    margin-top: 10px !important;
  }

  .Text {
    font-size: 12px !important;
  }
  .social-container {
    width: 52% !important;
  }
  .Contect-Number {
    width: 75% !important;
  }
}
@media only screen and (max-width: 375px) {
  .social-container {
    width: 63% !important;
  }
  .Email-input {
    width: 80% !important;
  }
  .contact-Input {
    width: 80% !important;
  }
  .name-Input {
    width: 80% !important;
  }
  .Footer-Fields {
    margin-top: 10%;
  }
  .App-dev {
    height: 538px !important;
    margin-top: 55px;
  }
  .All-Apps1 {
    height: 559px !important;
  }
  .web-dev {
    height: 721px !important;
  }

  .projects {
    font-size: 11px !important;
    margin-top: 10px !important;
    font-weight: 500;
  }
  .App-dev1 {
    height: 550px !important;
  }
  .App-dev {
    height: 510px !important;
  }
}
@media only screen and (max-width: 320px) {
  .Contect-Number {
    width: 72%;
  }

  .projects {
    font-size: 8px !important;
    margin-top: 10px !important;
    font-weight: 500;
  }
  .Email-to {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 320px) {
  .App-dev {
    height: 631px !important;
  }
  .App-dev1 {
    height: 597px !important;
  }
  .web-dev {
    height: 782px !important;
  }
  .mobileicon1 {
    margin-top: -103px !important;
  }
  .web-dev-1 {
    margin-top: -200px;
    height: 790px !important;
  }
  .web-dev-2 {
    height: 536px !important;
  }
  .All-Apps1 {
    height: 660px !important;
  }
  .All-Apps {
    height: 400px !important;
    margin-top: 41px !important;
    font-weight: 500;
  }
  .All-Apps2 {
    height: 539px !important;
  }
}
