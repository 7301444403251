@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
}
.header-comp-mobile {
  position: relative;
  right: 40px !important;
}

.Navcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  width: 100%;
  position: relative;
  justify-content: center;
  height: 100px;
}
.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 14px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}
.header-comp-mobile {
  display: none;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .header-comp-mobile {
    display: block;
    margin-left: 31px !important;
    width: 115px !important;
    font-size: 11px !important
    ;
  }
  .header-comp-web {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0px;
    top: 70px;
    background-color: #fef7e500;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 331px;
    background: #160761;
    height: 581px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
@media (max-width: 768px) {
  .nav-elements.active {
    width: 215px;
    background: #160761;
    height: 500px;
  }
}
