.Technologies-P1 {
  background-position: center;
}
.image {
  margin-left: 10%;
}
.Tech-Image {
  width: 80%;
}
@media only screen and (max-width: 1280px) {
  .Tech-Solutions {
    width: 25% !important;
  }

  .social-pages {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .Footer-Fields {
    width: 35%;
  }
  .Footer-Button {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .Footer-Button {
    font-size: 12px !important;
    width: 250px !important;
  }
  .Name-Field {
    flex-direction: column;
    margin: 0 !important;
  }
  .input-Contact {
    margin-top: 5% !important;
  }
  .social-container {
    margin-top: 10% !important;
    flex-direction: column;
    margin-bottom: 5%;
  }
  .Footer-Fields {
    width: 30%;
  }
  .name-Input {
    width: 100% !important;
  }
  .social-pages {
    margin-top: 3%;
    width: 100%;
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .Header-Items {
    display: none;
  }
  .Heading-Details-Details {
    width: 80% !important;
  }
  .p3-headig {
    font-size: 32px !important;
  }
  .main-Heading-technologies {
    font-size: 32px !important;
  }
  .Heading-Details-Details {
    font-size: 16px !important;
  }
  .p3-br {
    display: none !important;
  }
  .Tech-Solutions {
    margin-top: 25% !important;
    width: 50% !important;
  }
  .Email-input {
    width: 80% !important;
  }
  .P2-Div {
    flex-direction: column !important;
    align-items: center;
    margin-top: -15%;
  }
  .Imag-Div {
    display: none !important;
  }
  .Footer-components {
    flex-direction: column;
  }
  .Footer-Fields {
    width: 100%;
  }
  .name-Input {
    width: 40% !important;
  }
  .contact-Input {
    width: 60% !important;
  }
  .social-container {
    margin-bottom: 50px;
    width: 40% !important;
    align-items: start !important;
  }
  .Contect-Number {
    margin-top: 10%;
    width: 30%;
  }
  .Footer-Button {
    width: 200px !important;
  }
  .social-pages {
    justify-content: space-between;
    margin-top: 10%;
  }
  .p3-headi {
    font-size: 32px !important;
  }
  .p3-Details {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 500px) {
  .Contect-Number {
    width: 60%;
  }
  .Heading-Details-Details {
    width: 100% !important;
  }
  .p1-br {
    display: none;
  }
  .main-Heading {
    font-size: 35px !important ;
  }
  .Heading-Details {
    font-size: 18px !important;
  }
  .social-container {
    width: 52% !important;
  }
  .Tech-Solutions {
    width: 60% !important;
    margin-top: 30% !important;
  }
}
@media only screen and (max-width: 375px) {
  .social-container {
    width: 63% !important;
  }
  .Footer-Fields {
    margin-top: 10%;
  }
  .main-Heading {
    font-size: 25px !important ;
  }
  .Heading-Details {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 320px) {
  .Contect-Number {
    width: 72%;
  }
}
