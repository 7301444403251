.index {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index .div {
  background-color: #ffffff;
  border: 1px none;
  height: 4513px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.index .overlap {
  height: 672px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 100%;
}

.index .artboard {
  height: 671px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 100%;
}
.innerDiv{
  display: flex;
    width: 372px;
    justify-content: space-between;
    color: #fff;
}
.talkbtn{
  width: 100px;
  background: linear-gradient(264.78deg, #1E43DC -11.3%, #0BA2EA 129.77%);
  box-shadow: 0px 22px 11px -20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: #ffff;
  text-align: center;
  padding: 15px 20px;
}
.index .rectangle {
  background-color: #d9d9d91a;
  /* border-radius: 25px 25px 0px 0px; */
  height: 92px;
  left: 1px;
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  width: 100%;
}

.index .logo {
  height: 72px;
  left: 209px;
  object-fit: cover;
  /* position: absolute; */
  top: 10px;
  width: 72px;
}

.index .rectangle-2 {
  background: linear-gradient(180deg, rgb(30, 67, 220) 0%, rgb(11, 162, 234) 100%);
  /* background-color: #01eff7; */
  border-radius: 5px;
  box-shadow: 0px 22px 11px -20px #00000026;
  height: 43px;
  left: 1107px;
  /* position: absolute; */
  top: 25px;
  width: 149px;
}

.index .text-wrapper {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1147px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 33px;
}

.index .text-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 628px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.index .text-wrapper-3 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 565px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.index .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 745px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.index .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 826px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.index .group {
  height: 322px;
  left: 136px;
  position: absolute;
  top: 221px;
  width: 390px;
}

.index .transforming-your {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 50.2px;
  position: absolute;
  top: 0;
}

.index .p {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  top: 115px;
  width: 373px;
}

.index .overlap-wrapper {
  height: 53px;
  left: 0;
  position: absolute;
  top: 269px;
  width: 349px;
}

.index .overlap-group {
  background-color: #ffffff;
  border-radius: 5px;
  height: 53px;
  position: relative;
  width: 347px;
}

.index .text-wrapper-6 {
  color: #1e43dc;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 54px;
  letter-spacing: 0;
  line-height: 17.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.index .overlap-group-wrapper {
  height: 26px;
  left: 247px;
  position: absolute;
  top: 13px;
  width: 86px;
}

.index .div-wrapper {
  background: linear-gradient(180deg, rgb(30, 67, 220) 0%, rgb(11, 162, 234) 100%);
  border-radius: 5px;
  box-shadow: 0px 22px 11px -20px #00000026;
  height: 26px;
  position: relative;
  width: 84px;
}

.index .text-wrapper-7 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 9px;
  font-weight: 600;
  left: 19px;
  letter-spacing: 0;
  line-height: 11.3px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.index .vector {
  height: 13px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 17px;
}

.index .group-wrapper {
  background-color: #ffffff;
  height: 671px;
  left: 0;
  position: absolute;
  top: 676px;
  width: 100%;
}

.index .group-2 {
  height: 402px;
  left: 135px;
  position: relative;
  top: 134px;
  width: 1185px;
}

.index .image {
  height: 402px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 519px;
}

.index .group-3 {
  height: 94px;
  left: 694px;
  position: absolute;
  top: 242px;
  width: 102px;
}

.index .free-quality-icon-wrapper {
  background-color: #01eff759;
  border-radius: 30px;
  height: 60px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 60px;
}

.index .free-quality-icon {
  height: 46px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 46px;
}

.index .text-wrapper-8 {
  color: #0b163f;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.index .group-4 {
  height: 94px;
  left: 1017px;
  position: absolute;
  top: 242px;
  width: 138px;
}

.index .handshake-wrapper {
  background-color: #01eff759;
  border-radius: 30px;
  height: 60px;
  left: 38px;
  position: absolute;
  top: 0;
  width: 60px;
}

.index .handshake {
  height: 52px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 52px;
}

.index .text-wrapper-9 {
  color: #0b163f;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.index .group-5 {
  height: 94px;
  left: 864px;
  position: absolute;
  top: 242px;
  width: 85px;
}

.index .element-wrapper {
  background-color: #01eff759;
  border-radius: 30px;
  height: 60px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 60px;
}

.index .element {
  height: 46px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 46px;
}

.index .text-wrapper-10 {
  color: #0b163f;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.index .text-wrapper-11 {
  color: #2d3748;
  font-family: "Poppins", Helvetica;
  font-size: 35px;
  font-weight: 700;
  left: 693px;
  letter-spacing: 0;
  line-height: 38.7px;
  position: absolute;
  top: 52px;
  white-space: nowrap;
}

.index .text-wrapper-12 {
  color: #12062e;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 693px;
  letter-spacing: 0;
  line-height: 22.1px;
  position: absolute;
  top: 117px;
  width: 486px;
}

.index .text-wrapper-13 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 693px;
  letter-spacing: 0;
  line-height: 22.1px;
  position: absolute;
  top: 19px;
  white-space: nowrap;
}

.index .overlap-2 {
  height: 1414px;
  left: 0;
  position: absolute;
  top: 1347px;
  width: 100%;
}

.index .group-6 {
  height: 960px;
  left: 0;
  position: absolute;
  top: 454px;
  width: 100%;
}

.index .overlap-group-2 {
  height: 960px;
  position: relative;
}

.index .rectangle-3 {
  background-color: #f4f4f4;
  height: 738px;
  left: 0;
  position: absolute;
  top: 222px;
  width: 100%;
}

.index .img {
  height: 400px;
  left: 733px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 707px;
}

.index .artboard-2 {
  height: 671px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.index .group-7 {
  height: 365px;
  left: 477px;
  position: absolute;
  top: 150px;
  width: 489px;
}

.index .we-deliver-best {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 35px;
  font-weight: 700;
  left: 4px;
  letter-spacing: 0;
  line-height: 45.5px;
  position: absolute;
  text-align: center;
  top: 0;
}

.index .span {
  color: #01eff7;
}

.index .text-wrapper-14 {
  color: #ffffff;
}

.index .text-wrapper-15 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 116px;
  width: 485px;
}

.index .group-8 {
  height: 48px;
  left: 154px;
  position: absolute;
  top: 317px;
  width: 177px;
}

.index .group-9 {
  height: 48px;
}

.index .group-10 {
  height: 48px;
  width: 177px;
}

.index .group-11 {
  height: 48px;
  width: 179px;
}

.index .overlap-group-3 {
  background: linear-gradient(180deg, rgb(30, 67, 220) 0%, rgb(11, 162, 234) 100%);
  border-radius: 5px;
  box-shadow: 0px 22px 11px -20px #00000026;
  height: 48px;
  position: relative;
  width: 177px;
}

.index .text-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}

.index .group-12 {
  height: 319px;
  left: 434px;
  position: absolute;
  top: 918px;
  width: 570px;
}

.index .text-wrapper-17 {
  color: #2d3748;
  font-family: "Poppins", Helvetica;
  font-size: 35px;
  font-weight: 700;
  left: 534px;
  letter-spacing: 0;
  line-height: 38.7px;
  position: absolute;
  text-align: center;
  top: 824px;
  white-space: nowrap;
}

.index .text-wrapper-18 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 657px;
  letter-spacing: 0;
  line-height: 22.1px;
  position: absolute;
  text-align: center;
  top: 791px;
  white-space: nowrap;
}

.index .vector-wrapper {
  background-color: #3945ba;
  border-radius: 5px;
  height: 70px;
  left: 268px;
  position: absolute;
  top: 980px;
  width: 70px;
}

.index .vector-2 {
  height: 50px;
  left: 14px;
  position: absolute;
  top: 10px;
  width: 42px;
}

.index .img-wrapper {
  background-color: #71b9e9;
  border-radius: 5px;
  height: 70px;
  left: 389px;
  position: absolute;
  top: 1107px;
  width: 70px;
}

.index .group-13 {
  height: 50px;
  left: 15px;
  position: absolute;
  top: 10px;
  width: 40px;
}

.index .group-14 {
  height: 70px;
  left: 1000px;
  position: absolute;
  top: 1105px;
  width: 330px;
}

.index .group-15 {
  background-color: #69a741;
  border-radius: 5px;
  height: 70px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 70px;
}

.index .vector-3 {
  height: 50px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 50px;
}

.index .group-16 {
  background-color: #0ba2ea;
  border-radius: 5px;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.index .vector-4 {
  height: 50px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 50px;
}

.index .group-17 {
  background-color: #1a55de;
  border-radius: 5px;
  height: 70px;
  left: 135px;
  position: absolute;
  top: 1105px;
  width: 70px;
}

.index .vector-5 {
  height: 50px;
  left: 14px;
  position: absolute;
  top: 10px;
  width: 43px;
}

.index .group-18 {
  background-color: #01eff7;
  border-radius: 5px;
  height: 70px;
  left: 268px;
  position: absolute;
  top: 1229px;
  width: 70px;
}

.index .vector-6 {
  height: 50px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 56px;
}

.index .group-19 {
  height: 319px;
  left: 1130px;
  position: absolute;
  top: 980px;
  width: 70px;
}

.index .group-20 {
  background-color: #01eff7;
  border-radius: 5px;
  height: 70px;
  left: 0;
  position: absolute;
  top: 249px;
  width: 70px;
}

.index .vector-7 {
  height: 50px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 50px;
}

.index .element-atom-wrapper {
  background-color: #3945ba;
  border-radius: 5px;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.index .element-atom {
  height: 50px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 56px;
}

.index .group-21 {
  height: 242px;
  left: 0;
  position: absolute;
  top: 2763px;
  width: 100%;
}

.index .overlap-3 {
  height: 242px;
  position: relative;
  width: 100%;
}

.index .group-22 {
  height: 242px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.index .overlap-4 {
  background: linear-gradient(180deg, rgb(4, 37, 115) 0%, rgb(50, 23, 94) 100%);
  height: 242px;
  position: relative;
  width: 100%;
}

.index .flex-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  height: 49px;
  left: 137px;
  position: absolute;
  top: 149px;
  width: 827px;
}

.index .text {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.7px;
  position: relative;
  white-space: nowrap;
  width: 827px;
}

.index .text-wrapper-19 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.7px;
}

.index .span-wrapper {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.7px;
  position: relative;
  white-space: nowrap;
  width: 827px;
}

.index .text-wrapper-20 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.7px;
}

.index .text-wrapper-21 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 135px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 98px;
  white-space: nowrap;
}

.index .group-23 {
  height: 48px;
  left: 1078px;
  position: absolute;
  top: 145px;
  width: 177px;
}

.index .group-24 {
  height: 48px;
  width: 179px;
}

.index .overlap-group-4 {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  position: relative;
  width: 177px;
}

.index .text-wrapper-22 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}

.index .logo-2 {
  height: 58px;
  left: 135px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 58px;
}

.index .overlap-5 {
  height: 704px;
  left: 131px;
  position: absolute;
  top: 3266px;
  width: 1186px;
}

.index .union {
  height: 27px;
  left: 878px;
  position: absolute;
  top: 602px;
  width: 52px;
}

.index .union-2 {
  height: 17px;
  left: 818px;
  position: absolute;
  top: 607px;
  width: 32px;
}

.index .overlap-6 {
  height: 626px;
  left: 73px;
  position: absolute;
  top: 78px;
  width: 1015px;
}

.index .group-25 {
  height: 214px;
  left: 37px;
  position: absolute;
  top: 357px;
  width: 472px;
}

.index .group-26 {
  height: 214px;
  left: 543px;
  position: absolute;
  top: 76px;
  width: 472px;
}

.index .ellipse {
  height: 129px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 497px;
  width: 129px;
}

.index .ellipse-2 {
  height: 105px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 489px;
  width: 105px;
}

.index .testim {
  height: 439px;
  left: 158px;
  position: absolute;
  top: 50px;
  width: 732px;
}

.index .overlap-7 {
  height: 439px;
  position: relative;
  width: 730px;
}

.index .rectangle-4 {
  background-color: #ffffff;
  border-radius: 20px;
  height: 331px;
  left: 56px;
  position: absolute;
  top: 108px;
  width: 618px;
}

.index .rectangle-5 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 18px 52.85px #d7e3f880;
  height: 363px;
  left: 26px;
  position: absolute;
  top: 56px;
  width: 678px;
}

.index .rectangle-6 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 18px 52.85px #d7e3f880;
  height: 391px;
  left: 0;
  position: absolute;
  top: 0;
  width: 730px;
}

.index .no-other-ecommerce {
  color: #265771;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 82px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 228px;
  width: 566px;
}

.index .text-wrapper-23 {
  color: #272d4e;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 446px;
  letter-spacing: 0;
  line-height: 33.5px;
  position: absolute;
  text-align: center;
  top: 183px;
  white-space: nowrap;
}

.index .text-wrapper-24 {
  color: #272d4e;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 499px;
  letter-spacing: 0;
  line-height: 33.5px;
  position: absolute;
  text-align: center;
  top: 211px;
  white-space: nowrap;
}

.index .ellipse-3 {
  height: 183px;
  left: 431px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 183px;
}

.index .ellipse-4 {
  height: 171px;
  left: 437px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 171px;
}

.index .overlap-8 {
  height: 202px;
  left: 984px;
  position: absolute;
  top: 373px;
  width: 202px;
}

.index .ellipse-5 {
  height: 162px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 28px;
  width: 162px;
}

.index .ellipse-6 {
  height: 202px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 202px;
}

.index .overlap-9 {
  height: 172px;
  left: 53px;
  position: absolute;
  top: 10px;
  width: 156px;
}

.index .ellipse-7 {
  height: 156px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 156px;
}

.index .ellipse-8 {
  height: 136px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 136px;
}

.index .overlap-10 {
  height: 142px;
  left: 1042px;
  position: absolute;
  top: 0;
  width: 142px;
}

.index .ellipse-9 {
  height: 116px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 116px;
}

.index .ellipse-10 {
  height: 142px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 142px;
}

.index .ellipse-wrapper {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/ellipse-9@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 125px;
  left: 0;
  position: absolute;
  top: 254px;
  width: 125px;
}

.index .ellipse-11 {
  height: 93px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 93px;
}

.index .text-wrapper-25 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 578px;
  letter-spacing: 0;
  line-height: 22.1px;
  position: absolute;
  top: 3164px;
  white-space: nowrap;
}

.index .don-t-take-our-word {
  color: #2d3748;
  font-family: "Poppins", Helvetica;
  font-size: 35px;
  font-weight: 700;
  left: 498px;
  letter-spacing: 0;
  line-height: 33.5px;
  position: absolute;
  text-align: center;
  top: 3196px;
}

.index .overlap-11 {
  height: 439px;
  left: 0;
  position: absolute;
  top: 4074px;
  width: 100%;
}

.index .group-27 {
  background: linear-gradient(180deg, rgb(4, 37, 115) 0%, rgb(50, 23, 94) 100%);
  height: 439px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.index .group-28 {
  height: 294px;
  left: 135px;
  position: relative;
  top: 98px;
  width: 1183px;
}

.index .group-29 {
  height: 184px;
  left: 242px;
  position: absolute;
  top: 0;
  width: 103px;
}

.index .text-wrapper-26 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 162px;
  white-space: nowrap;
}

.index .text-wrapper-27 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 124px;
  white-space: nowrap;
}

.index .text-wrapper-28 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 87px;
  white-space: nowrap;
}

.index .text-wrapper-29 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.index .text-wrapper-30 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.index .group-30 {
  height: 294px;
  left: 404px;
  position: absolute;
  top: 0;
  width: 201px;
}

.index .text-wrapper-31 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.index .text-wrapper-32 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.index .text-wrapper-33 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 87px;
  white-space: nowrap;
}

.index .text-wrapper-34 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 124px;
  white-space: nowrap;
}

.index .text-wrapper-35 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.index .text-wrapper-36 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 197px;
  white-space: nowrap;
}

.index .text-wrapper-37 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 235px;
  white-space: nowrap;
}

.index .text-wrapper-38 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.78;
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.index .text-wrapper-39 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 255px;
  white-space: nowrap;
}

.index .text-wrapper-40 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 66px;
}

.index .text-wrapper-41 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 88px;
}

.index .vector-8 {
  height: 22px;
  left: -1px;
  position: absolute;
  top: 253px;
  width: 22px;
}

.index .text-wrapper-42 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 721px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 56px;
}

.index .text-wrapper-43 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 942px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 159px;
}

.index .text-wrapper-44 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 721px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 103px;
  white-space: nowrap;
  width: 133px;
}

.index .rectangle-7 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 721px;
  position: absolute;
  top: 35px;
  width: 167px;
}

.index .rectangle-8 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 942px;
  position: absolute;
  top: 35px;
  width: 217px;
}

.index .rectangle-9 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 721px;
  position: absolute;
  top: 138px;
  width: 438px;
}

.index .vector-9 {
  height: 30px;
  left: 919px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.index .vector-10 {
  height: 30px;
  left: 975px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.index .vector-11 {
  height: 29px;
  left: 1031px;
  position: absolute;
  top: 257px;
  width: 29px;
}

.index .vector-12 {
  height: 30px;
  left: 1085px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.index .vector-13 {
  height: 30px;
  left: 1141px;
  position: absolute;
  top: 257px;
  width: 30px;
}

.index .logo-3 {
  height: 58px;
  left: 135px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 58px;
}

.index .group-31 {
  height: 48px;
  left: 856px;
  position: absolute;
  top: 341px;
  width: 179px;
}

.index .overlap-12 {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  position: relative;
  width: 177px;
}

.index .text-wrapper-45 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}
