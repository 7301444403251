@media only screen and (min-width: 2100px) {
  .Footer-Fields {
    width: 24% !important;
  }
}
.no-br {
  display: none !important;
}
@media only screen and (max-width: 1280px) {
  .social-pages {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .Footer-Fields {
    width: 35%;
  }
  .Footer-Button {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .Footer-Button {
    font-size: 12px !important;
    width: 250px !important;
  }
  .Name-Field {
    flex-direction: column;
    margin: 0 !important;
  }
  .Input-Field-Div {
    width: 70% !important;
  }
  .input-Contact {
    margin-top: 5% !important;
  }
  .social-container {
    margin-top: 10% !important;
    flex-direction: column;
    margin-bottom: 5%;
  }
  .Footer-Fields {
    width: 30%;
  }
  .name-Input {
    width: 100% !important;
  }
  .social-pages {
    margin-top: 3%;
    width: 100%;
    display: flex;
  }
  .no-br {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .Footer-components {
    flex-direction: column;
  }
  .Main-Heading {
    font-size: 32px !important;
  }
  .Details {
    font-size: 16px !important;
  }
  .Input-Field-Div {
    width: 79% !important;
  }
  .Email-input {
    width: 80% !important;
  }
  .header-Comp {
    display: none !important;
  }
  .main-Div-image {
    display: none !important;
  }
  .Benefits-Div {
    flex-direction: column !important;
  }
  .Should-join-Us {
    align-items: start !important;
  }
  .team-Work-Div {
    flex-direction: column !important;
  }
  .Footer-Fields {
    width: 100%;
  }
  .name-Input {
    width: 40% !important;
  }
  .contact-Input {
    width: 60% !important;
  }
  .social-container {
    margin-bottom: 50px;
    width: 40% !important;
    align-items: start !important;
  }
  .Contect-Number {
    margin-top: 10%;
    width: 30%;
  }
  .Footer-Button {
    width: 200px !important;
  }
  .social-pages {
    justify-content: space-between;
    margin-top: 10%;
  }
  .Benefits {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 500px) {
  .Contect-Number {
    width: 60%;
  }
  .career-brtag {
    display: none !important;
  }
  .Career-Openings {
    font-size: 10px !important;
  }
  .Join-our-Team {
    font-size: 20px !important;
  }
  .social-container {
    width: 52% !important;
  }
  .Main-Heading {
    font-size: 32px !important;
  }
  .Input-Field-Div {
    width: 80% !important;
  }
  .Details {
    font-size: 16px !important;
  }
  .input-Fields-Div {
    flex-direction: column !important;
    align-items: center;
  }
  .Jobs {
    font-size: 25px !important;
  }
  .Label {
    margin-top: 5% !important;
  }
  .Input-Field-Div {
    height: 925px !important;
  }
  .Cv-Select {
    width: 80% !important;
  }
}
@media only screen and (max-width: 375px) {
  .social-container {
    width: 63% !important;
  }
  .Footer-Fields {
    margin-top: 10%;
  }
  .Main-Heading {
    font-size: 29px !important;
  }
  .Cv-Select {
    width: 91% !important;
  }
}
@media only screen and (max-width: 320px) {
  .Contect-Number {
    width: 72%;
  }
  .Main-Heading {
    font-size: 25px !important;
  }
  .Input-width {
    width: 200px !important;
  }
}
@media only screen and (max-width: 280px) {
  .submit-button {
    margin-left: 17px !important;
  }
  .file {
    margin: 34px 0px 25px 15px !important;
  }
  .Email-font {
    font-size: 13px !important;
  }
  .Contect-Number {
    width: 83% !important;
  }
  .Details {
    font-size: 15px !important;
  }
  .Contect-Number {
    width: 88% !important;
    font-size: 13px !important;
  }
}
