.careerindex {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.careerindex .careerdiv {
  background-color: #ffffff;
  border: 1px none;
  height: 4608px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.careerindex .careeroverlap {
  height: 653px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 100%;
}

.careerindex .careerrectangle {
  background-color: #f8f8f8;
  height: 567px;
  left: 1px;
  position: absolute;
  top: 86px;
  width: 100%;
}

.careerindex .careerrectangle-2 {
  background-color: #160761;
  /* border-radius: 25px 25px 0px 0px; */
  height: 92px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.careerindex .careerlogo {
  height: 72px;
  left: 208px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 72px;
}

.careerindex .careerrectangle-3 {
  background: linear-gradient(180deg, rgb(30, 67, 220) 0%, rgb(11, 162, 234) 100%);
  border-radius: 5px;
  box-shadow: 0px 22px 11px -20px #00000026;
  height: 43px;
  left: 1106px;
  position: absolute;
  top: 25px;
  width: 149px;
}

.careerindex .careertext-wrapper {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1146px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 33px;
}

.careerindex .careerartboard {
  height: 424px;
  left: 729px;
  object-fit: cover;
  position: absolute;
  top: 160px;
  width: 639px;
}

.careerindex .careercareers-that-make-an {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 136px;
  letter-spacing: 0;
  line-height: 50.career2px;
  position: absolute;
  top: 220px;
  width: 386px;
}

.careerindex .careerp {
  color: #12062e;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 136px;
  letter-spacing: 0;
  line-height: 20.career1px;
  position: absolute;
  top: 335px;
  width: 373px;
}

.careerindex .careergroup {
  height: 48px;
  left: 136px;
  position: absolute;
  top: 502px;
  width: 179px;
}

.careerindex .careeroverlap-group {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  position: relative;
  width: 177px;
}

.careerindex .careertext-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 45px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}

.careerindex .careernavbar {
  height: 21px;
  left: 566px;
  position: absolute;
  top: 42px;
  width: 319px;
}

.careerindex .careertext-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 63px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careertext-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careertext-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 180px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careertext-wrapper-6 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 261px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careeroverlap-2 {
  height: 439px;
  left: 0;
  position: absolute;
  top: 4169px;
  width: 100%;
}

.careerindex .careergroup-wrapper {
  background: linear-gradient(180deg, rgb(4, 37, 115) 0%, rgb(50, 23, 94) 100%);
  height: 439px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.careerindex .careergroup-2 {
  height: 294px;
  left: 135px;
  position: relative;
  top: 98px;
  width: 1183px;
}

.careerindex .careergroup-3 {
  height: 184px;
  left: 242px;
  position: absolute;
  top: 0;
  width: 103px;
}

.careerindex .careertext-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 162px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-8 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 124px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-9 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 87px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-10 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-11 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 28.career8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.careerindex .careergroup-4 {
  height: 294px;
  left: 404px;
  position: absolute;
  top: 0;
  width: 201px;
}

.careerindex .careertext-wrapper-12 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 28.career8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-13 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-14 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 87px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-15 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 124px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-17 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 197px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-18 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 235px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-19 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22.career4px;
  opacity: 0.career78;
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-20 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 255px;
  white-space: nowrap;
}

.careerindex .careertext-wrapper-21 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 66px;
}

.careerindex .careertext-wrapper-22 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 88px;
}

.careerindex .careervector {
  height: 22px;
  left: -1px;
  position: absolute;
  top: 253px;
  width: 22px;
}

.careerindex .careertext-wrapper-23 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 721px;
  letter-spacing: 0;
  line-height: 25.career2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 56px;
}

.careerindex .careertext-wrapper-24 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 942px;
  letter-spacing: 0;
  line-height: 25.career2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 159px;
}

.careerindex .careertext-wrapper-25 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 721px;
  letter-spacing: 0;
  line-height: 25.career2px;
  position: absolute;
  top: 103px;
  white-space: nowrap;
  width: 133px;
}

.careerindex .careerrectangle-4 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 721px;
  position: absolute;
  top: 35px;
  width: 167px;
}

.careerindex .careerrectangle-5 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 942px;
  position: absolute;
  top: 35px;
  width: 217px;
}

.careerindex .careerrectangle-6 {
  border: 1px solid;
  border-color: #ebf0f9;
  border-radius: 5px;
  height: 38px;
  left: 721px;
  position: absolute;
  top: 138px;
  width: 438px;
}

.careerindex .careerimg {
  height: 30px;
  left: 919px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.careerindex .careervector-2 {
  height: 30px;
  left: 975px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.careerindex .careervector-3 {
  height: 29px;
  left: 1031px;
  position: absolute;
  top: 257px;
  width: 29px;
}

.careerindex .careervector-4 {
  height: 30px;
  left: 1085px;
  position: absolute;
  top: 256px;
  width: 30px;
}

.careerindex .careervector-5 {
  height: 30px;
  left: 1141px;
  position: absolute;
  top: 257px;
  width: 30px;
}

.careerindex .careerlogo-2 {
  height: 58px;
  left: 135px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 58px;
}

.careerindex .careerrectangle-7 {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  left: 856px;
  position: absolute;
  top: 344px;
  width: 177px;
}

.careerindex .careertext-wrapper-26 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 891px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 355px;
}

.careerindex .careerwhy-you-should-join {
  color: #2d3748;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 700;
  left: 135px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 791px;
}

.careerindex .careertext-wrapper-27 {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 135px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 764px;
}

.careerindex .careerwe-want-to-feel-like {
  color: #2d3748;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 135px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 908px;
  width: 439px;
}

.careerindex .careeroverlap-3 {
  height: 209px;
  left: 665px;
  position: absolute;
  top: 757px;
  width: 592px;
}

.careerindex .careersecured-future {
  height: 209px;
  left: 294px;
  position: absolute;
  top: 0;
  width: 298px;
}

.careerindex .careertext-wrapper-28 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
}

.careerindex .careericon-wrapper {
  background-color: #f1f7e8;
  border-radius: 4px;
  height: 74px;
  left: 0;
  position: absolute;
  top: 0;
  width: 74px;
}

.careerindex .careericon {
  height: 38px;
  left: 18px;
  position: absolute;
  top: 18px;
  width: 38px;
}

.careerindex .careertext-wrapper-29 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 139px;
  width: 294px;
}

.careerindex .careerteam-work {
  height: 209px;
  left: 0;
  position: absolute;
  top: 0;
  width: 298px;
}

.careerindex .careertext-wrapper-30 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
}

.careerindex .careervector-wrapper {
  background-color: #e7f2ff;
  border-radius: 4px;
  height: 74px;
  left: 0;
  position: absolute;
  top: 0;
  width: 74px;
}

.careerindex .careervector-6 {
  height: 38px;
  left: 18px;
  position: absolute;
  top: 18px;
  width: 38px;
}

.careerindex .careertext-wrapper-31 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 139px;
  width: 294px;
}

.careerindex .careeroverlap-4 {
  height: 209px;
  left: 665px;
  position: absolute;
  top: 1008px;
  width: 592px;
}

.careerindex .careerupgrate-skills {
  height: 209px;
  left: 294px;
  position: absolute;
  top: 0;
  width: 298px;
}

.careerindex .careertext-wrapper-32 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
}

.careerindex .careerimg-wrapper {
  background-color: #ffeeed;
  border-radius: 4px;
  height: 74px;
  left: 0;
  position: absolute;
  top: 0;
  width: 74px;
}

.careerindex .careericon-2 {
  height: 35px;
  left: 15px;
  position: absolute;
  top: 19px;
  width: 38px;
}

.careerindex .careertext-wrapper-33 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 139px;
  width: 294px;
}

.careerindex .careerlearning-opportunity {
  height: 209px;
  left: 0;
  position: absolute;
  top: 0;
  width: 298px;
}

.careerindex .careertext-wrapper-34 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
}

.careerindex .careeroverlap-5 {
  background-color: #eff2f5;
  border-radius: 4px;
  height: 74px;
  left: 0;
  position: absolute;
  top: 0;
  width: 74px;
}

.careerindex .careervector-7 {
  height: 46px;
  left: 8px;
  position: absolute;
  top: 14px;
  width: 58px;
}

.careerindex .careertext-wrapper-35 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 139px;
  width: 294px;
}

.careerindex .careerframe {
  height: 1080px;
  left: 2px;
  position: absolute;
  top: 1937px;
  width: 100%;
}

.careerindex .careeroverlap-group-wrapper {
  height: 1080px;
  width: 100%;
}

.careerindex .careeroverlap-group-2 {
  background-image: url(https://anima-uploads.careers3.careeramazonaws.careercom/projects/648b449e01a3e041d7fd0d49/releases/648b46a0e4074b96d3116b9c/img/border.careersvg);
  background-size: 100%;
  height: 1080px;
  position: relative;
  width: 100%;
}

.careerindex .careerleft-side {
  height: 27px;
  left: 271px;
  position: absolute;
  top: 263px;
  width: 896px;
}

.careerindex .careergroup-5 {
  height: 27px;
  position: relative;
  width: 906px;
}

.careerindex .careerHT-admin {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careersupport {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 415px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careerengineering {
  color: #01eff7;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 179px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careerd-esign {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 575px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careerdigital-marketing {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 718px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.careerindex .careergroup-6 {
  height: 580px;
  left: 270px;
  position: absolute;
  top: 364px;
  width: 897px;
}

.careerindex .careerelement {
  height: 110px;
  left: -4px;
  position: absolute;
  top: 238px;
  width: 905px;
}

.careerindex .careerelement-2 {
  height: 108px;
  left: -4px;
  position: absolute;
  top: 360px;
  width: 905px;
}

.careerindex .careerelement-3 {
  height: 108px;
  left: -4px;
  position: absolute;
  top: 480px;
  width: 905px;
}

.careerindex .careerelement-4 {
  height: 108px;
  left: -4px;
  position: absolute;
  top: 120px;
  width: 905px;
}

.careerindex .careerelement-5 {
  height: 110px;
  left: -4px;
  position: absolute;
  top: -2px;
  width: 905px;
}

.careerindex .careertext-wrapper-36 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 544px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 126px;
}

.careerindex .careertext-wrapper-37 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 453px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 189px;
  width: 530px;
}

.careerindex .careeroverlap-6 {
  background-color: #f0eded;
  height: 1151px;
  left: 0;
  position: absolute;
  top: 3018px;
  width: 100%;
}

.careerindex .careertext-wrapper-38 {
  color: #001833;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 512px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 173px;
}

.careerindex .careeroverlap-7 {
  height: 677px;
  left: 389px;
  position: absolute;
  top: 315px;
  width: 663px;
}

.careerindex .careergroup-7 {
  height: 677px;
  left: 0;
  position: absolute;
  top: 0;
  width: 663px;
}

.careerindex .careeroverlap-wrapper {
  height: 677px;
  width: 679px;
}

.careerindex .careeroverlap-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e7ecef;
  border-radius: 10px;
  height: 677px;
  position: relative;
  width: 663px;
}

.careerindex .careeroverlap-9 {
  height: 229px;
  left: 52px;
  position: absolute;
  top: 257px;
  width: 563px;
}

.careerindex .careergroup-8 {
  height: 81px;
  left: 0;
  position: absolute;
  top: 0;
  width: 563px;
}

.careerindex .careertext-wrapper-39 {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 19px;
  left: 1px;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 177px;
}

.careerindex .careeroverlap-group-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 269px;
}

.careerindex .careerstroke-copy {
  height: 8px;
  left: 240px;
  position: absolute;
  top: 20px;
  width: 13px;
}

.careerindex .careermerpati-sidomuncul {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careerstroke-copy-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 290px;
  position: absolute;
  top: 31px;
  width: 269px;
}

.careerindex .careerstroke-copy-2 {
  height: 8px;
  left: 242px;
  position: absolute;
  top: 20px;
  width: 13px;
}

.careerindex .careeremployment-type-wrapper {
  height: 19px;
  left: 307px;
  position: absolute;
  top: 0;
  width: 179px;
}

.careerindex .careeremployment-type {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 19px;
  left: 0;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 177px;
}

.careerindex .careergroup-9 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 42px;
  width: 465px;
}

.careerindex .careerrectangle-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 99px;
  width: 269px;
}

.careerindex .careermerpati-sidomuncul-2 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 307px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careermerpati-sidomuncul-3 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 159px;
  white-space: nowrap;
  width: 260px;
}

.careerindex .careerresume-cv-upload-wrapper {
  height: 19px;
  left: 1px;
  position: absolute;
  top: 110px;
  width: 179px;
}

.careerindex .careerresume-cv-upload {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 19px;
  left: 0;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 177px;
}

.careerindex .careertext-wrapper-40 {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 16px;
  left: 52px;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
  width: 120px;
}

.careerindex .careertext-wrapper-41 {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 19px;
  left: 52px;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 149px;
  white-space: nowrap;
  width: 177px;
}

.careerindex .careermerpati-sidomuncul-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 53px;
  position: absolute;
  top: 71px;
  width: 269px;
}

.careerindex .careermerpati-sidomuncul-4 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careerdiv-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 53px;
  position: absolute;
  top: 181px;
  width: 269px;
}

.careerindex .careermerpati-sidomuncul-5 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careertext-wrapper-42 {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 16px;
  left: 342px;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
  width: 120px;
}

.careerindex .careeroverlap-10 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 343px;
  position: absolute;
  top: 71px;
  width: 269px;
}

.careerindex .careermerpati-sidomuncul-6 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careertext-wrapper-43 {
  color: #1e43dc;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 19px;
  left: 342px;
  letter-spacing: 0;
  line-height: 66px;
  position: absolute;
  top: 149px;
  white-space: nowrap;
  width: 177px;
}

.careerindex .careeroverlap-11 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 10px;
  height: 50px;
  left: 343px;
  position: absolute;
  top: 181px;
  width: 269px;
}

.careerindex .careermerpati-sidomuncul-7 {
  color: #637381;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 154px;
}

.careerindex .careerelement-6 {
  height: 23px;
  left: 283px;
  object-fit: cover;
  position: absolute;
  top: 411px;
  width: 23px;
}

.careerindex .careerrectangle-9 {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  left: 53px;
  position: absolute;
  top: 558px;
  width: 177px;
}

.careerindex .careertext-wrapper-44 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 112px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 569px;
}

.careerindex .careerhiring {
  height: 496px;
  left: 122px;
  object-fit: cover;
  position: absolute;
  top: 1338px;
  width: 590px;
}

.careerindex .careerwe-are-hiring-come {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 728px;
  letter-spacing: 0;
  line-height: 50.career2px;
  position: absolute;
  top: 1337px;
}

.careerindex .careerspan {
  color: #01eff7;
}

.careerindex .careertext-wrapper-45 {
  color: #2d3748;
}

.careerindex .careertext-wrapper-46 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 728px;
  letter-spacing: 0;
  line-height: 22.career1px;
  position: absolute;
  top: 1471px;
  width: 476px;
}

.careerindex .careergroup-10 {
  height: 48px;
  left: 728px;
  position: absolute;
  top: 1669px;
  width: 179px;
}

.careerindex .careeroverlap-12 {
  background: linear-gradient(180deg, rgb(11, 162, 234) 0%, rgb(30, 67, 220) 100%);
  border-radius: 15px;
  height: 48px;
  position: relative;
  width: 177px;
}

.careerindex .careertext-wrapper-47 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 45px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}
