@media only screen and (min-width: 2100px) {
  .Footer-Fields {
    width: 24% !important;
  }
}
.social-pages {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.image {
  margin-left: 0% !important;
}
@media only screen and (max-width: 1280px) {
  .Footer-Fields {
    width: 35%;
  }
  .Footer-Button {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .Footer-Button {
    font-size: 12px !important;
    width: 250px !important;
  }
  .Name-Field {
    flex-direction: column;
    margin: 0 !important;
  }
  .input-Contact {
    margin-top: 5% !important;
  }
  .social-container {
    margin-top: 10% !important;
    flex-direction: column;
  }
  .Footer-Fields {
    width: 30%;
  }
  .name-Input {
    width: 100% !important;
  }
  .social-pages {
    margin-top: 3%;
    width: 100%;
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .Main-Heading-div {
    margin-top: 10% !important;
  }
  .letsTalk-Text {
    font-size: 32px !important;
  }
  .image {
    width: 400px !important;
    margin-left: 0 !important;
  }
  .P2 {
    flex-wrap: wrap !important;
  }
  .P3-Div {
    flex-direction: column !important;
  }
  .P3 {
    height: 1350px !important;
  }
  .LetsTalk-Main-Div {
    height: 700px !important;
  }
  .Footer-components {
    flex-direction: column;
  }
  .Footer-Fields {
    width: 100%;
  }
  .name-Input {
    width: 40% !important;
  }
  .contact-Input {
    width: 60% !important;
  }
  .social-container {
    margin-bottom: 50px;
    width: 40% !important;
    align-items: start !important;
  }
  .Heasder-Components {
    display: none !important;
  }
  .Contect-Number {
    margin-top: 10%;
    width: 30%;
  }
  .Footer-Button {
    width: 200px !important;
  }
  .social-pages {
    justify-content: space-between;
    margin-top: 10%;
  }
}
@media only screen and (max-width: 500px) {
  .Contect-Number {
    width: 60%;
  }
  .experiance {
    font-size: 60px !important;
  }
  .years {
    font-size: 20px !important;
  }
  .mb-3 {
    width: 95% !important;
  }
  .Idea {
    font-size: 32px !important;
  }
  .conact-us {
    font-size: 35px !important;
  }
  .social-container {
    width: 52% !important;
  }
  .LetsTalk-Main-Div {
    height: 1000px !important;
  }
  .Main-Div-Comp {
    flex-direction: column-reverse !important;
  }
  .P2 {
    height: 550px !important;
  }
  .Input-Div {
    flex-direction: column !important;
  }
  .input-field {
    width: 94% !important;
    margin-top: 7% !important;
  }
  .P3 {
    height: 1500px !important;
  }
}
@media only screen and (max-width: 375px) {
  .social-container {
    width: 63% !important;
  }
  .Footer-Fields {
    margin-top: 10%;
  }
  .image {
    width: 320px !important;
  }
  .conact-us {
    font-size: 50px !important;
  }
  .Idea {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 320px) {
  .Contect-Number {
    width: 72%;
  }
  .conact-us {
    font-size: 40px !important;
  }
  .Idea {
    font-size: 25px !important;
  }
}
